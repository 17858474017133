import { Component, OnInit } from '@angular/core';
// import {MatDialogRef} from "@angular/material/dialog";
// import {CommonModalsService} from "../../../services/modals/common-modals.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-output-path-info',
  templateUrl: './output-path-info.component.html',
  styleUrls: ['./output-path-info.component.scss']
})
export class OutputPathInfoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OutputPathInfoComponent>,
  ) { }

  ngOnInit(): void {
  }

}
