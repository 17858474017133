import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {doc, Firestore, setDoc} from "@angular/fire/firestore";
import {AuthService} from "../../../services/auth.service";
import {HelperService} from "../../../services/helper.service";
import {WorkspaceService} from "../../../services/workspace.service";

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      error: any,
      status: number
      modal: string,
      action: string,
      data: any
    },
    private firestore: Firestore,
    private authSvc: AuthService,
    private helperSvc: HelperService,
    private workspaceSvc: WorkspaceService
  ) { }

  async ngOnInit() {
    if (this.data.error) {
      await setDoc(doc(this.firestore, 'app-errors', this.helperSvc.generateId(25)), {
        error: this.data.error,
        createdAt: new Date().getTime(),
        url: window.location.href,
        uid: this.authSvc.uid ?? '',
        email: this.authSvc.email ?? '',
        workspaceId: this.workspaceSvc.getWorkspaceIdFromUrl(),
        errorInModal: this.data.modal ?? '',
        action: this.data.action,
        data: this.helperSvc.sanitizeFirebaseDoc(this.data.data)
      })
    }
  }

}
