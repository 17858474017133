import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {ModalHeaderModule} from "./shared/layout/modal-header/modal-header.module";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {ModalFooterModule} from "./shared/layout/modal-footer/modal-footer.module";
import {TourMatMenuModule} from "ngx-ui-tour-md-menu";
import {RouterModule} from "@angular/router";
import {MonacoEditorModule} from "ngx-monaco-editor-v2";
import { OutputPathInfoComponent } from './shared/modals/info/output-path-info/output-path-info.component';
import {PrettyPrintJsonModule} from "./shared/components/pretty-print-json/pretty-print-json.module";
import {getFunctions, provideFunctions} from "@angular/fire/functions";
import {HttpClientModule} from "@angular/common/http";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {ErrorModalModule} from "./shared/modals/common/error-modal/error-modal.module";
import {ToastrModule} from "ngx-toastr";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MAT_CHIPS_DEFAULT_OPTIONS} from "@angular/material/chips";
import {COMMA, ENTER, SPACE} from "@angular/cdk/keycodes";
import {MatTooltipModule} from "@angular/material/tooltip";
import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    AppComponent,
    OutputPathInfoComponent,
  ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideAuth(() => getAuth()),
      provideFirestore(() => getFirestore()),
      provideFunctions(() => getFunctions()),
      BrowserAnimationsModule,
      MatDialogModule,
      MatSelectModule,
      MatSnackBarModule,
      ModalHeaderModule,
      ReactiveFormsModule,
      MatInputModule,
      ModalFooterModule,
      // TourMatMenuModule.forRoot(),
      RouterModule,
      MonacoEditorModule.forRoot(),
      PrettyPrintJsonModule,
      HttpClientModule,
      MatAutocompleteModule,
      ErrorModalModule,
      ToastrModule.forRoot(),
      MatTooltipModule,
      NgChartsModule
    ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [COMMA, ENTER]
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
