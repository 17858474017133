import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorModalComponent } from './error-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import {ModalFooterModule} from "../../../layout/modal-footer/modal-footer.module";
import {ModalHeaderModule} from "../../../layout/modal-header/modal-header.module";



@NgModule({
  declarations: [
    ErrorModalComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ModalFooterModule,
    ModalHeaderModule
  ]
})
export class ErrorModalModule { }
