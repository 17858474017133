import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from './modal-header.component';
import {MatDialogModule} from "@angular/material/dialog";



@NgModule({
  declarations: [
    ModalHeaderComponent
  ],
  exports: [
    ModalHeaderComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule
  ]
})
export class ModalHeaderModule { }
