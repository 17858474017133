import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "./auth.service";
import {ComputationReferenceInterface} from "../interfaces/computation/computation-reference.interface";
import * as _ from "lodash";
import {ComputationCategoryType} from "../interfaces/computation/computation-category.type";
import {isAfter} from "date-fns";
import JSConfetti from "js-confetti";

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  uid: string
  email: string
  constructor(
    private _snackBar: MatSnackBar,
    private authSvc: AuthService
  ) {
    authSvc.user$.subscribe((user) => {
      this.uid = user?.uid ?? ''
      this.email = user?.email ?? ''
    })
  }

  copyText(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      this._snackBar.open('Copied in your clipboard!', 'Close', {
        duration: 3000
      });
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  generateId(length: number = 8): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  scrollTo(elementId: string): boolean {
    const element = document.getElementById(elementId);
    if (!element) {
      return false;
    }
    const y = element.getBoundingClientRect().top + window.scrollY - 80;
    window.scrollTo({top: y, behavior: 'smooth'});
    return true;
  }

  scrollIntoView(elementId: string): boolean {
    const element = document.getElementById(elementId);
    if (!element) {
      return false;
    }
    document.getElementById("btn-add-property")?.scrollIntoView(
      {
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      }
    )
    return true;
  }

  setMetadata(object: any): any {
    if (object.createdAt) { // Object already exists, set update data
      object.lastUpdate = new Date().getTime()
      object.lastUpdateBy = this.email
    } else { // New object
      object.createdAt = new Date().getTime()
      object.createdBy = this.email
    }
    return object
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  groupComputations(computations: ComputationReferenceInterface[]) {
    const result: {
      group: ComputationCategoryType
      computations: ComputationReferenceInterface[]
    }[] = []
    computations.forEach((computation) => {
      const group = result.find(x => x.group === computation.group)
      if (!group) {
        result.push({
          group: computation.group,
          computations: [computation]
        })
      } else {
        group.computations.push(computation)
      }
    })
    console.log('Result', result)
    return result
  }

  _deleteArrayElement(array: string[], element: string): string[] {
    const index = array.findIndex(x => x === element)
    if (index !== -1) {
      array.splice(index, 1)
    }
    return array
  }

  _addArrayElement(array: string[], element: string): string[] {
    const index = array.findIndex(x => x === element)
    if (index === -1) {
      array.push(element)
    }
    return array
  }

  isEqual(obj1: any, obj2: any): boolean {
    if (obj1 === undefined || obj2 === undefined) {
      return true
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // tslint:disable-next-line:triple-equals
    if (keys1.length != keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const bothAreObjects = typeof (obj1[key]) === `object` && typeof (obj2[key]) === `object` && obj1[key] !== null && obj2[key] !== null;
      if ((!bothAreObjects && (obj1[key] !== obj2[key])) || (bothAreObjects && !this.isEqual(obj1[key], obj2[key]))) {
        return false;
      }
    }
    return true;
  }

  _isExpired(expirationDate?: number) {
    return isAfter(new Date(), new Date(expirationDate ?? 0))
  }

  toJsonPropertyName(str: string): string {
    // Replace non-alphanumeric characters with underscores
    let result = str.replace(/[^a-zA-Z0-9_]/g, '_');

    // If the first character is a number, prepend an underscore
    if (result.match(/^[0-9]/)) {
      result = '_' + result;
    }

    return result;
  }

  getAllKeyPaths(obj: any, parentKey: string = '', onlyLists: boolean = false): string[] {
    let keyPaths: string[] = [];

    if (!obj) {
      return keyPaths;
    }

    const keys = Object.keys(obj);

    for (const key of keys) {
      const currentKeyPath = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        const subKeyPaths = this.getAllKeyPaths(obj[key], currentKeyPath, onlyLists);
        keyPaths = keyPaths.concat(subKeyPaths);
      } else if (Array.isArray(obj[key])) {
        if (onlyLists) {
          keyPaths.push(currentKeyPath);
        } else {
          keyPaths.push(currentKeyPath);
          const subKeyPaths = this.getAllKeyPaths(obj[key], currentKeyPath, onlyLists);
          keyPaths = keyPaths.concat(subKeyPaths);
        }
      } else if (!onlyLists) {
        keyPaths.push(currentKeyPath);
      }
    }

    return keyPaths;
  }


  copyElement(element: any, fieldsToUpdate: string[]): any {
    const copy = _.cloneDeep(element)
    fieldsToUpdate.forEach((field) => {
      copy[field] += '_copy'
    })
    copy['id'] = this.generateId()
    return copy
  }

  canBeUnwrapped(obj: any, isListProcessing = false): boolean {
    console.log('Unwrap object', obj)
    const keys = Object.keys(obj);
    console.log('Unwrap object keys', keys)
    return keys.length === 1 && (typeof obj[keys[0]] === 'object' || isListProcessing);
  }

  unwrapObject(obj: Record<string, any>, failSafe = false): Record<string, any> | string | null {
    const keys = Object.keys(obj);
    if (keys.length === 1 && typeof obj[keys[0]] === 'object') {
    // if (keys.length === 1) {
      return obj[keys[0]];
    }
    return (failSafe) ? obj : null;
  }

  replaceUndefinedByString(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => this.replaceUndefinedByString(item));
    }

    const result: { [key: string]: any } = {};
    for (const key in obj) {
      if (obj[key] === undefined) {
        result[key] = 'undefined';
      } else {
        result[key] = this.replaceUndefinedByString(obj[key]);
      }
    }

    return result;
  }

  flattenJsonObject(obj: any, parentKey?: string): any {
    let result: any = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
          // Recursively flatten nested objects
          result = { ...result, ...this.flattenJsonObject(obj[key], newKey) };
        } else {
          result[newKey] = obj[key];
        }
      }
    }

    return result;
  }


  unifyJsonObjects(objects: any[]): any[] {
    // Get all unique keys from all unified 'data' objects
    const allKeys = Array.from(new Set(objects.flatMap(obj => Object.keys(obj.data))));

    // Iterate through each object and add missing keys with null values for the unified 'data' property
    const unifiedObjects = objects.map(obj => {
      const newData: any = { ...obj.data }; // Create a new object to avoid modifying the original 'data'
      allKeys.forEach(key => {
        if (!(key in newData)) {
          newData[key] = null; // Assign null if key is missing
        }
      });
      return { ...obj, data: newData };
    });

    return unifiedObjects;
  }

  sanitizeFirebaseDoc(data: any): any {
    data = this.replaceUndefinedWithNull(data)
    data = this.replaceArraysOfArrays(data)
    return data
  }

  replaceUndefinedWithNull(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => this.replaceUndefinedWithNull(item));
    }

    const result: { [key: string]: any } = {};
    for (const key in obj) {
      if (obj[key] === undefined) {
        result[key] = null;
      } else {
        result[key] = this.replaceUndefinedWithNull(obj[key]);
      }
    }

    return result;
  }

  replaceArraysOfArrays(obj: any): any {
    if (Array.isArray(obj)) {
      if (obj.some((item) => Array.isArray(item))) {
        return `list:${JSON.stringify(obj)}`;
      }
    }

    if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        obj[key] = this.replaceArraysOfArrays(obj[key]);
      }
    }

    return obj;
  }

  generateBasicAuthHeader(email: string, password: string): string {
    const credentials = `${email}:${password}`;
    const base64Credentials = window.btoa(credentials);
    return `Basic ${base64Credentials}`;
  }

  downloadJson(jsonObject: object, filename: string) {
    const dataStr = JSON.stringify(jsonObject, null, 2);
    const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', filename);
    linkElement.click();
  }

  async popConfetti() {
    const confetti = new JSConfetti()
    const options = {
    }
    confetti.addConfetti(options).then()
    setTimeout(() => { confetti.addConfetti(options)}, 300)
    setTimeout(() => { confetti.addConfetti(options)}, 600)
  }

  sortObjectKeys(value: any): any {
    if (Array.isArray(value)) {
      return value.map(item => this.sortObjectKeys(item));
    } else if (value !== null && typeof value === 'object') {
      return Object.keys(value)
        .sort()
        .reduce((result: any, key) => {
          _.set(result, key, this.sortObjectKeys(value[key]));
          return result;
        }, {});
    } else {
      return value;
    }
  }
}
