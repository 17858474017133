import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalFooterComponent } from './modal-footer.component';
import {MatRippleModule} from "@angular/material/core";
import {TourMatMenuModule} from "ngx-ui-tour-md-menu";



@NgModule({
    declarations: [
        ModalFooterComponent
    ],
    exports: [
        ModalFooterComponent
    ],
    imports: [
        CommonModule,
        MatRippleModule,
        TourMatMenuModule
    ]
})
export class ModalFooterModule { }
