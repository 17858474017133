import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./views/user/user.module').then(m => m.UserModule) },
  { path: 'auth', loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule) },
  { path: 'redeem-code', loadChildren: () => import('./views/redeem-code/redeem-code.module').then(m => m.RedeemCodeModule) },
  { path: 'integrations/:vendor', loadChildren: () => import('./views/integrations/integrations-auth.module').then(m => m.IntegrationsAuthModule) },
  { path: 'checkout', loadChildren: () => import('./views/checkout/checkout.module').then(m => m.CheckoutModule) },
  { path: 'test', loadChildren: () => import('./views/test/test.module').then(m => m.TestModule) },

  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
