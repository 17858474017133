<div class="flex justify-between mt-auto px-3">
  <button matRipple class="btn btn-neutral" [disabled]="processing" (click)="onClose.emit()">
    <i class="fas fa-times mr-1"></i>
    Close
  </button>
  <span>
    <button matRipple class="btn btn-accent mr-2" (click)="onShare.emit()" *ngIf="!processing && share" [disabled]="disabled">
      <i class="fas fa-share mr-1"></i>
      Share
    </button>
    <button matRipple class="btn btn-primary" (click)="onAdd.emit()" *ngIf="!processing && add" [disabled]="disabled" tourAnchor="add">
      <i class="fas fa-plus mr-1"></i>
      Add
    </button>
    <button matRipple class="btn btn-primary" tourAnchor="save" (click)="onSave.emit()" *ngIf="!processing && save" [disabled]="disabled" id="modalFooterSave">
      <i class="fas fa-save mr-1"></i>
      Save
    </button>
    <button matRipple class="btn btn-primary" (click)="onConfirm.emit()" *ngIf="!processing && confirm" [disabled]="disabled">
      <i class="fas fa-check mr-1"></i>
      Confirm
    </button>
    <button matRipple class="btn btn-primary" (click)="onSend.emit()" *ngIf="!processing && send" [disabled]="disabled">
      <i class="fas fa-paper-plane mr-1"></i>
      Send
    </button>
    <button matRipple class="btn btn-primary" (click)="onTest.emit()" *ngIf="!processing && test" [disabled]="disabled">
      <i class="fas fa-vial mr-1"></i>
      Run test
    </button>
    <button matRipple class="btn btn-primary" (click)="onCreate.emit()" *ngIf="!processing && create" [disabled]="disabled">
      <i class="fas fa-plus mr-1"></i>
      Create
    </button>
    <button matRipple class="btn btn-primary" (click)="onRun.emit()" *ngIf="!processing && run" [disabled]="disabled">
      <i class="fas fa-running mr-1"></i>
      Run
    </button>
    <button class="btn btn-processing" *ngIf="processing">
      <i class="fas fa-gear"></i>
      <span class="ml-1">Processing...</span>
    </button>
  </span>
</div>
